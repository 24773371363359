// Tablet
@media screen and (min-width: 768px) and (max-width: 1159px) {
  .coms-pane .pr2--panelHeaderSection {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    .lp--commentContext {
      padding: 0;
    }
  }

  .coms-pane .lp--activityEditorContainer {
    margin-top: auto;
    padding: 30px 10px 20px;
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: var(--white);
  }

  .content-panel.coms-pane.open {
    transform: translateX(100%);
  }
  .content-panel.coms-pane {
    right: initial;
    transform: translateX(200%);
  }

  .lp--activityEditorSection .ql-toolbar.ql-snow {
    .lp--fullScreenPanel & {
      margin-top: -41px;
    }

    &.show {
      .lp--fullScreenPanel & {
        margin-top: 0;
      }
    }
  }

  .navbar-default .navbar-brand {
    padding-left: 15px;
  }

  .lp--teacherPanel-context-group .node-pane {
    width: 100%;
  }

  .lp--add-to-collection-wrapper {
    left: initial !important;
    right: 200px;

    .lp--add-to-collection {
      width: auto;

      &:before,
      &:after {
        left: initial !important;
        right: 45px;
      }

      ul li.new input {
        width: auto;
      }
    }
  }

  .lp--profileSubnav-list {
    .archived {
      display: none;
    }
  }

  .lp--activityEditorSection .lp--activityEditorAttachmentItem {
    max-width: 100%;

    .lp--activityEditorAttachmentRemove {
      flex-shrink: 0;
    }

    .lp--activityEditorAttachmentLabel {
      word-break: break-word;
      max-width: 100%;
    }
  }

  .lp--teacherPanel-contextView {
    width: calc(100% - 433px);
    min-width: 0;
  }

  .lp--teacherPanel-contextView .lp--progress .lp--progress-progressBar {
    width: 100%;
  }

  .lp--progress.student-progress {
    white-space: nowrap;
    width: 100%;
  }

  .lp--teacherPanelExportStudentProgress.csv {
    display: none;
  }

  body {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }
}

// Mobile
@media screen and (max-width: 767px) {
  body.horizon {
    .pr2--darkCoverContainer,
    .pr2--featuredProductJumbotron {
      &:first-child:after {
        display: none;
      }
    }
  }

  .lp--modal {
    width: 100%;
    left: 0px;
    height: 100%;
    max-height: 100%;
    z-index: 23;
    top: 0px;
    margin-left: 0px;
    position: absolute;

    &.open {
      top: 0px;
    }

    .lp--formRow {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      a {
        padding: 10px 0px;
        border: none;
      }

      .lp--authenticateSSOLogin {
        margin-top: 20px;
      }

      .lp--authenticateSSOLogin,
      .lp--authenticatePasswordLogin {
        width: 100%;
        margin-left: 0px;
      }

      .lp--authenticatePasswordReminder,
      .lp--authenticateModalTypeSwitch {
        margin-left: 0px;
      }

      .lp--authenticateModalTypeSwitch {
        margin-top: 10px;
      }
    }
  }

  .lp--authenticateModal {
    .lp--authenticateModalBodyViewport {
      .lp--authenticateModalBodyWrapper {
        width: 100%;

        .lp--authenticateModalBody {
          width: 100%;
          flex: none;

          .lp--authenticateModalBodyContainer {
            padding: 0px 20px;
          }
        }
      }
    }

    .lp--categoryPicker-grade {
      width: 100%;
      margin: 5px 0px;
      border-bottom: none;
    }

    .lp--categoryPicker-categoryBlock li {
      font-size: 14px;
    }

    .lp--categoryPicker-categoryBlock .category-title {
      margin-top: 0px;
    }
  }

  div.autocomplete-suggestions.lp--authenticationModalAutocomplete {
    position: absolute;
    width: 100% !important;
    left: 0px !important;
    margin-left: 0px;
    max-height: 300px;
  }

  .node-pane,
  .hub-pane,
  .circle-pane,
  .coms-pane {
    width: 100%;
    z-index: 6;
    position: absolute;
    margin-top: 60px;
    overflow-x: hidden;
  }

  .pr2--circlePanelDescription,
  .pr2--nodePanelBody,
  .lp--nodePanelUserSection,
  .pr2--hubPanelDescription,
  .lp--hubNavigation {
    padding: 0px 20px;
  }

  .pr2--panelHeader {
    padding: 0px 20px;
  }

  .coms-pane {
    left: 0px;
    right: initial;
    min-width: 100%;
    height: initial;
    min-height: 100%;
    height: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .panel-content {
      position: absolute;
      width: 100%;
    }

    .pr2--nodePanelBody {
      overflow-y: visible;
      padding-bottom: 50px;
    }

    .lp--activityEditorContainer {
      margin-top: 20px;
      padding: 0px 10px 20px 10px;
    }
  }

  .content-panel + .content-panel {
    pointer-events: none;
  }

  .content-panel.coms-pane {
    transform: translateX(100%);
  }

  .content-panel.coms-pane.open {
    transform: translateX(0%);
  }

  .content-panel.node-pane,
  .content-panel.hub-pane,
  .content-panel.circle-pane {
    transform: translateX(-100%);
  }

  .circle-panel-description {
    padding: 17px;
  }

  .pr2--darkCoverContainer {
    padding-bottom: 0;
  }

  .pr2--subNav {
    margin-bottom: 20px;
  }

  .pr2--subNav-list.lp--profileSubnav-list {
    display: flex;
    justify-content: space-between;

    li {
      margin-right: 0;
    }

    a {
      font-size: 12px;
    }
  }

  .pr2-adaptiveSearchField {
    margin-top: 0;
  }

  .my-loops__header {
    display: flex;
    align-items: baseline;
  }

  .lp--subNav-newLoop {
    position: absolute;
    right: 10px;
    margin-top: 55px;
    z-index: 1;
  }

  .teacher-panel-link {
    padding: 5px 13px;
    border-radius: 50%;
    background-size: cover;
    border: none;
    background-position: center;
  }

  .teacher-panel-link__text {
    display: none;
  }

  .menu-progress {
    display: none;
  }

  .lp--loopListItem .lp--progress-progressBar {
    width: 40px;
  }

  .lp--panelNavigation {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  .lp--panelNavigation-activity {
    font-size: 12px;
  }

  .lp--panelNavigation .pr2--listIconWrapper-home {
    font-size: 14px;
  }

  .lp--panelNavigation .navigation-arrow {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .lp--panelNavigation-activity.pr2--listIconWrapper-home:not(.hub) .pr2--listIconWrapper-homeIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .lp--quizWidget-questions .lp--quizWidgetQuestion .lp--quizWidgetQuestionBody .lp--quizWidgetQuestionBodyMedia {
    width: 280px;
    height: 191px;
  }

  .legend-viewer .concept-header {
    vertical-align: top;
  }

  .legend-viewer .description-body {
    width: initial;
  }

  .lp--answerPicker {
    max-width: initial;
    left: initial;
  }

  .pr2--createSessionPopin {
    max-width: 340px;
    .lp--dateRangeSelectorInputs {
      text-align: center;

      .pr2--formInput {
        width: 100%;
      }
    }
  }

  .lp--modal.categoryPicker {
    margin-left: 0;
  }

  .lp--categoryPicker-modalContent {
    padding: 75px 20px;
  }

  .lp--loopListItem-categories {
    width: auto;
  }

  .lp--categoryPicker-modal.small {
    width: auto;
    margin-left: 0;
  }

  .lp--loopListItem-authors {
    max-width: 180px;
  }

  .lp--loopListItem .prList-item-linkwrapper {
    padding: 0;
  }

  .lp--loopListCollectionItem .lp--loopListItem-images {
    width: auto;
    min-width: auto;
  }

  .content-panel.coms-pane.open + .content-panel.node-pane.open {
    transform: translateX(-100%);
  }

  .coms-pane .pr2--panelHeaderSection {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    .lp--commentContext {
      padding: 0;
    }
  }

  .coms-pane .lp--activityEditorContainer {
    margin-top: auto;
    padding: 30px 10px 20px;
    position: sticky;
    bottom: 60px;
    left: 0;
    right: 0;
    background-color: var(--white);
  }

  .pr2--panelHeaderSection .pr2--panelHeaderToolFullscreen {
    display: none;
  }

  .lp--librarySearch-field {
    width: 100%;
  }

  .lp--authenticateModal.lp--authenticateModal-join-code {
    .lp--authenticateModalBodyViewport {
      padding: 10px;
    }
  }

  .navbar-default {
    position: fixed;
    background: var(--standardBlackBackground);
  }

  .lp--curriculumEditor {
    width: auto;
  }

  .lp--modal.alexandria {
    margin-left: 0;
  }

  .lp--categoryPicker-categoryBlock li {
    font-size: 14px;
  }

  .lp--circleRemixPopin {
    width: 95%;
  }

  .lp--teacherPanel-contextView {
    min-width: 100%;
  }

  .lp--teacherPanel-behaviorPanel {
    min-width: initial;
  }

  .lp--teacherPanel .coms-pane .lp--activityEditorContainer {
    bottom: 0;
  }

  .lp--errorPageJumbotron .lp--errorPageJumbotron-container {
    align-items: initial;
    align-content: initial;
    padding: 0 50px;
    margin: 0;

    .cover-heading {
      font-size: 52px;
      line-height: 52px;
      margin: 0;
      padding: 0;
    }
  }

  .lp--graphQuickLink {
    margin-left: 10px;
  }

  .lp--graphZoomButton {
    right: 20px;
  }

  .lp--add-to-collection-wrapper {
    left: initial !important;
    right: 200px;

    .lp--add-to-collection {
      width: auto;

      &:before,
      &:after {
        left: initial !important;
        right: 37px;
      }

      ul li.new input {
        width: auto;
      }
    }
  }

  .navbar-default .navbar-collapse a.lp--navbarHomeButton {
    padding-left: 18px;
    background: url(../../../public/assets/images/theme-2/icons/home-white.svg) 0px 9px no-repeat;
    background-size: 13px 13px;
    background-position: 10px;
    margin-right: 15px;
    padding-left: 30px;
  }

  .ql-snow .ql-editor {
    font-size: 16px;
  }

  .lp--activityEditorSection .lp--activityEditorAttachmentItem {
    max-width: 100%;

    .lp--activityEditorAttachmentRemove {
      flex-shrink: 0;
    }

    .lp--activityEditorAttachmentLabel {
      word-break: break-word;
      max-width: 100%;
    }
  }

  .lp--profileSubnav-list {
    .archived,
    .review {
      display: none;
    }
  }

  .lp--loopListItem-authors > li:not(:first-child) {
    display: none;
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 18px;
  }

  .lp--teacherPanel-contextView .lp--progress .lp--progress-progressBar {
    width: 100%;
  }

  .lp--progress.student-progress {
    white-space: nowrap;
    width: 100%;
  }

  .lp--teacherPanelExportStudentProgress.csv {
    display: none;
  }

  body {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  .lp--containerHorizon {
    display: none;
  }

  .lp--tab-container {
    padding-top: 20px;
  }

  .lp--infoText {
    margin-bottom: 20px;
  }

  .lp--loopListItem-image {
    margin-right: 15px;
    margin-left: 15px;
  }

  .lp--shareCodeSection {
    word-break: break-word;
  }

  .lp--modal ~ #panel-mask {
    z-index: 10;
  }

  #panel-wrapper {
    z-index: 20;
  }
}

@media screen and (max-width: 680px) {
  .lp--activityEditorSection .lp--activityEditor .ql-toolbar.ql-snow {
    margin-top: -89px;
  }
}

@media screen and (min-width: 680px) {
  .lp--activityEditorSection .lp--activityEditor .ql-toolbar.ql-snow {
    margin-top: -65px;
  }
}

@media screen and (min-width: 768px) {
  .lp--activityEditorSection .lp--activityEditor .ql-toolbar.ql-snow {
    margin-top: -89px;
  }
}

@media screen and (min-width: 1160px) {
  .lp--activityEditorSection .lp--activityEditor .ql-toolbar.ql-snow {
    margin-top: -65px;
  }
}
